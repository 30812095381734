"use client";
import { CenterBox } from "@/components/styled";
import Image from "next/image";
import LoadingAnimation from "@/public/assets/lottie-animations/loading.json";
import { useLottiePlayer } from "@/hooks/useLottiePlayer";
import lightLogo from "@/public/assets/images/svgimages/rushlinkicon_lightmode.svg"

import darkLogo from "@/public/assets/images/svgimages/rushlinkicon_darkmode.svg"
import { useContext } from "react";
import { AppState } from "../context/AppState";
export default function Page() {
  const DynamicLottiePlayer = useLottiePlayer();
  const { theme } = useContext(AppState);
  const darkTheme = theme === "dark";
  return (
    <CenterBox width={"100%"} height={"100%"}>
      <CenterBox mt="6xl">
      <Image src= {darkTheme ? darkLogo : lightLogo} alt={"logo"} width={40} height={40}/>
        <DynamicLottiePlayer
          autoplay
          loop
          style={{
            height: "30%",

            backgroundRepeat: "no-repeat",
          }}
          src={LoadingAnimation}
        ></DynamicLottiePlayer>
      </CenterBox>
    </CenterBox>
  );
}
